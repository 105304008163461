/* global google */

import React from 'react';
import { Marker } from '@react-google-maps/api';
import colors from '@thrivelot/tailwind/colors';
import { MapHomeIcon } from '@thrivelot/styles';

const getHomeIcon = (color = colors.green.main, size = 30) => ({
  url: `data:image/svg+xml;charset=UTF-8,${MapHomeIcon({
    size: `${size}`,
    color,
  })}`,
  scaledSize: new google.maps.Size(size, size),
});

const MapElementHome = ({ location }) => {
  const options = {
    icon: getHomeIcon(),
    optimized: false,
  };

  return <Marker position={location} labelContent="Home" options={options} />;
};

export { MapElementHome };
