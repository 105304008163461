import React, { useCallback, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useAuth, ModelKitProvider, PlantsProvider } from '@thrivelot/hooks';
import { LayoutSuspense, RoutingAuthentication } from '@thrivelot/components';
import { authConfig, modelConfig } from '@thrivelot/config';
import LogRocket from 'logrocket';
import { constructUuid } from '@thrivelot/utils';
import { CustomerRoutes } from './CustomerRoutes';

const Routing = () => {
  const auth = useAuth();

  useEffect(() => {
    if (auth?.authenticated && !auth?.groups?.includes('customer'))
      auth.signOut();
  }, [auth?.authenticated, auth?.groups, auth?.signOut]);

  // Identify LogRocket user only in production and when authenticated
  useEffect(() => {
    if (
      /thrivelot.com/.test(window.location.hostname) &&
      auth?.authenticated &&
      auth?.userId &&
      auth?.user?.attributes
    ) {
      try {
        LogRocket.identify(auth.userId, {
          name: auth?.user?.attributes?.name,
          email: auth?.user?.attributes?.email,
          cognitoId: auth?.user?.attributes?.sub,
        });

        window.Marker.setReporter({
          email: auth?.user?.attributes?.email,
          fullName: auth?.user?.attributes?.name,
        });

        window.pendo.initialize({
          visitor: {
            id: auth?.userId, // Required if user is logged in, default creates anonymous ID
            email: auth?.user?.attributes?.email, //  Recommended if using Pendo Feedback, or NPS Email
            full_name: auth?.user?.attributes?.name, // Recommended if using Pendo Feedback
            role: 'supplier',

            // You can add any additional visitor level key-values here,
            // as long as it's not one of the above reserved names.
          },
        });
      } catch (e) {
        console.log('Marker or Logrocket not loaded', e);
      }
    }
  }, [auth?.authenticated, auth?.userId, auth?.user?.attributes]);

  const onUpdateVariableInput = useCallback(
    (input) => ({
      ...input,
      updatedMeta: {
        id: constructUuid(),
        userCognitoId: auth?.user?.username,
        userId: auth?.userId,
        userEmail: auth?.user?.attributes?.email,
        userName: auth?.user?.attributes?.name,
        userGroups: auth?.groups && JSON.stringify(auth.groups),
        device: navigator.userAgent,
      },
    }),
    [auth]
  );

  if (auth.loading) return <LayoutSuspense />;

  return (
    <BrowserRouter>
      {auth?.authenticated ? (
        <ModelKitProvider
          authConfig={authConfig}
          modelConfig={{ ...modelConfig, onUpdateVariableInput }}
          auth={auth}
        >
          <PlantsProvider>
            <CustomerRoutes userId={auth?.userId} />
          </PlantsProvider>
        </ModelKitProvider>
      ) : (
        <RoutingAuthentication />
      )}
    </BrowserRouter>
  );
};

export { Routing };
