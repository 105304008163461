import React from 'react';
import { PageContainer } from '@thrivelot/stories';
import {
  CardAccount,
  CardSettingsPersonal,
  CardTermsOfUse,
} from '@thrivelot/components';

const PageSettings = () => (
  <PageContainer
    header="Settings"
    documentTitle="Settings"
    headerActions={
      <div className="text-brown-dark font-bold text-sm">{`App Version: ${window.appConfig.version}`}</div>
    }
  >
    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
      <CardAccount />
      <CardSettingsPersonal />
      <CardTermsOfUse />
    </div>
  </PageContainer>
);

export { PageSettings };
