import React from 'react';
import { LeafLoader, EmptyState } from '@thrivelot/stories';
import { useModelList } from '@thrivelot/hooks';
import { RowItemProjectInvoiceProposal } from '..';

const RowListProjectInvoiceProposalsSimple = ({ projectId }) => {
  const { models: invoiceProposals, loaded } = useModelList({
    modelName: 'InvoiceProposal',
    filter: { projectId: { eq: projectId }, status: { ne: 'draft' } },
  });

  if (!loaded) return <LeafLoader />;

  if ((invoiceProposals || []).length === 0)
    return (
      <EmptyState
        icon="LineItems"
        header="No orders here yet..."
        children={
          <div>
            When your service provider submits an order you can view it here.
          </div>
        }
      />
    );

  return (
    <div className="divide-y divide-tan-light">
      {invoiceProposals
        .filter((ip) => ip.status !== 'draft' && ip.name !== 'Site Visit')
        .map(({ id }) => (
          <RowItemProjectInvoiceProposal
            key={id}
            projectId={projectId}
            invoiceProposalId={id}
            to={id}
          />
        ))}
    </div>
  );
};

export { RowListProjectInvoiceProposalsSimple };
