import React from 'react';
import { useModel } from '@thrivelot/hooks';
import { PageContainer } from '@thrivelot/stories';
import { useParams } from 'react-router-dom';
import {
  HelpPrompt,
  ViewProjectInvoiceProposal,
  LinkBack,
} from '@thrivelot/components';

const PageViewProjectInvoiceProposal = () => {
  const { projectId, invoiceProposalId } = useParams();

  const { model: project, loaded: projectLoaded } = useModel({
    modelName: 'Project',
    id: projectId,
  });
  const { model: invoiceProposal, loaded } = useModel({
    modelName: 'InvoiceProposal',
    id: invoiceProposalId,
  });

  return (
    <PageContainer
      icon="LineItems"
      preheader={
        <div className="flex items-center justify-between">
          <LinkBack label="Back to Orders" to="../orders" />
          {/* <Link to="plant-palette">
            <Button
              icon="Leaf"
              kind="outline"
              color="green-main"
              label="View Plant Palette"
            />
          </Link> */}
        </div>
      }
      documentTitle={
        loaded &&
        projectLoaded &&
        [project?.details?.title, invoiceProposal.name]
          .filter((val) => val)
          .join(' - ')
      }
      help={
        <HelpPrompt header="About the Project Invoices page">
          Please read through and review your Order. When you're ready, approve
          the proposal and make the first payment.
        </HelpPrompt>
      }
    >
      <div className="m-auto max-w-6xl">
        <ViewProjectInvoiceProposal
          projectId={projectId}
          invoiceProposalId={invoiceProposalId}
        />
      </div>
    </PageContainer>
  );
};

export { PageViewProjectInvoiceProposal };
