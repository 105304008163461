import React from "react";
import { useModal, useModelList } from "@thrivelot/hooks";
import { LeafLoader } from "@thrivelot/stories";
import { ImageUserAvatar } from "../ImageUserAvatar";
import { Button } from "@thrivelot/stories";

const ServiceProvider = ({ ownerId, isLast }) => {
  const { models, loaded, loading } = useModelList({
    modelName: "Supplier",
    filter: { owners: { contains: ownerId } },
  });
  const { openModal } = useModal();

  if (!loaded || loading) return <LeafLoader />;

  const supplier = models[0];
  const { businessName, firstname, lastname, phoneNumber, email } = supplier || {};
  let supplierName = (firstname || lastname) && [firstname, lastname].join(" ");

  return (
    <div className="flex gap-4 p-2">
      <div className="flex justify-center items-center">
        <ImageUserAvatar user={supplier} size={100} />
        <div className="flex flex-col ml-5 justify-between h-full">
          <div className="flex flex-col">
            {supplierName && <div className="font-bold text-lg text-mulch-dark">{supplierName}</div>}
            {businessName && <div className="text-mulch-dark">{businessName}</div>}
          </div>
          <div className="flex flex-wrap">
            <a href={`tel:${phoneNumber}`} className="mr-2">
              <Button icon="Phone" kind="outline" color="green-main" size="extra-small" label="CALL" />
            </a>
            <a href={`mailto:${email}`} className="mr-2">
              <Button icon="Mail" kind="outline" color="green-main" size="extra-small" label="EMAIL" />
            </a>
            <Button
              icon="License"
              kind="outline"
              color="green-main"
              size="extra-small"
              label="BIO"
              onClick={() => openModal({ path: "ModalViewSupplierBio", supplierId: supplier.id })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { ServiceProvider };
