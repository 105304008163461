import React from 'react';
import { useModel } from '@thrivelot/hooks';
import { Clock, RowItemLoader, RowListItem } from '@thrivelot/stories';
import { constructAddressString, getProjectStage } from '@thrivelot/common';
import { dateFns } from '@thrivelot/date';
import { transformStringToTitleCase } from '@thrivelot/utils';
import { milestoneColorMap } from '@thrivelot/config';

const RowItemMyProject = ({ projectId }) => {
  const { model: project, loaded } = useModel({
    modelName: 'Project',
    id: projectId,
  });

  if (!loaded) return <RowItemLoader />;

  const { id } = project;
  const address = constructAddressString(project.details.address);
  const stage = getProjectStage(project);

  const projectName = project.details.title;

  return (
    <RowListItem
      to={`/projects/${id}`}
      header={projectName || address}
      subheader={
        <div className={`font-bold text-${milestoneColorMap[stage]}-dark`}>
          {transformStringToTitleCase(stage)}
        </div>
      }
      details={
        <>
          {project.updatedAt && (
            <div className="italic text-sm flex items-center">
              <div className="mr-1">{dateFns(project.updatedAt).fromNow()}</div>
              <Clock size={12} color="mulch-light" />
            </div>
          )}
        </>
      }
    />
  );
};

export { RowItemMyProject };
