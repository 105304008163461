import React from 'react';
import { useModel } from '@thrivelot/hooks';
import { PageContainer } from '@thrivelot/stories';
import { useParams } from 'react-router-dom';
import {
  HelpPrompt,
  RowListProjectInvoiceProposalsSimple,
} from '@thrivelot/components';

const PageProjectInvoiceProposals = () => {
  const { projectId } = useParams();
  const { model: project, loaded } = useModel({
    modelName: 'Project',
    id: projectId,
  });

  return (
    <PageContainer
      icon="LineItems"
      header="Orders"
      documentTitle={loaded && project.details.title}
      help={
        <HelpPrompt header="About the Project Invoices page">
          Here you can view a list of proposed and active Orders. Clicking on
          one of the cards will open up the Proposal where you can review,
          approve, and make payments.
        </HelpPrompt>
      }
    >
      <RowListProjectInvoiceProposalsSimple projectId={projectId} />
    </PageContainer>
  );
};

export { PageProjectInvoiceProposals };
