import React from 'react';
import { Button, PageContainer } from '@thrivelot/stories';
import { TLGoldBadgeIcon } from '@thrivelot/styles';
import { useMarker } from '@thrivelot/hooks';

const PageNoProjectsYet = () => {
  const { handleMarker } = useMarker();

  return (
    <PageContainer header="My project" documentTitle="No projects yet">
      <div className="flex flex-col justify-center items-center pt-20">
        <TLGoldBadgeIcon size={90} />
        <div className="text-brown-dark mt-5 text-center flex flex-col items-center">
          <div>Oops! It looks like you don't have any projects yet.</div>
          <div>
            If that doesn't seem right then please contact your ThriveLot rep
          </div>
          <div className="py-2 my-3">- or -</div>
          <Button
            icon="Bug"
            kind="outline"
            label="Submit a bug report"
            color="green-main"
            onClick={handleMarker}
          />
        </div>
      </div>
    </PageContainer>
  );
};

export { PageNoProjectsYet };
