import { HelpPrompt } from '@thrivelot/components';
import { PageContainer } from '@thrivelot/stories';
import React from 'react';
import { RowItemMyProject } from './RowItemMyProject';
import { PageNoProjectsYet } from '../PageNoProjectsYet';

const PageMyProjects = ({ projectIds }) => {
  if (projectIds.length === 0) return <PageNoProjectsYet />;
  return (
    <PageContainer
      icon="Plan"
      header="My Projects"
      documentTitle="My Projects"
      help={
        <HelpPrompt header="About your Projects page">
          This is a list view of your projects on the thrive lot platform.
        </HelpPrompt>
      }
    >
      {projectIds.map((projectId) => (
        <RowItemMyProject key={projectId} projectId={projectId} />
      ))}
    </PageContainer>
  );
};

export { PageMyProjects };
