import React from "react";
import { useModelUser } from "@thrivelot/common";
import { CardLoader } from "@thrivelot/stories";
import { Card } from "@thrivelot/stories";

const CardSettingsPersonal = () => {
  const { model: customer, updateModel: updateCustomer, loaded } = useModelUser({ modelName: "Customer" });

  if (!loaded) return <CardLoader />;

  return (
    <Card header="Personal">
      <div className="flex flex-col justify-around w-full h-full px-2 py-5">
        <div className="mb-2">
          <div className="text-green-dark text-sm font-bold">First Name</div>
          <input
            className="input"
            title="First name"
            placeholder="Your first name"
            value={customer.firstname || ""}
            onChange={(e) => updateCustomer({ firstname: e.target.value })}
          />
        </div>
        <div>
          <div className="text-green-dark text-sm font-bold">Last Name</div>
          <input
            className="input"
            title="Last name"
            placeholder="Your last name"
            value={customer.lastname || ""}
            onChange={(e) => updateCustomer({ lastname: e.target.value })}
          />
        </div>
      </div>
    </Card>
  );
};

export { CardSettingsPersonal };
