import React, { useCallback } from 'react';
import { Circle } from '@react-google-maps/api';
import { useMap } from '@thrivelot/hooks';
import colors from '@thrivelot/tailwind/colors';

const MapElementZone = ({ zone }) => {
  const { infoBoxId, toggleInfoBox } = useMap();

  const onClick = useCallback(() => {
    toggleInfoBox(zone.id);
  }, [toggleInfoBox, zone?.id]);

  if (!zone?.center) return null;

  const color = colors.zonesMap[zone.type];
  const options = {
    clickable: true,
    strokeColor: color,
    fillColor: color,
    fillOpacity: infoBoxId === zone.id ? 1 : 0.5,
  };

  return (
    <Circle
      center={zone.center}
      radius={5}
      options={options}
      onClick={onClick}
    />
  );
};

export { MapElementZone };
