import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { useMap, useModel } from '@thrivelot/hooks';
import { Card, Cancel, Feature } from '@thrivelot/stories';
import { isEmpty } from '@thrivelot/utils';

const ZoneInfoBox = () => {
  const { infoBoxId, toggleInfoBox } = useMap();
  const { projectId } = useParams();
  const { model: project } = useModel({ modelName: 'Project', id: projectId });

  const zone = useMemo(
    () => infoBoxId && project?.zones?.find(({ id }) => id === infoBoxId),
    [project?.zones, infoBoxId]
  );

  const features = useMemo(
    () =>
      infoBoxId &&
      zone?.orderedChildTagIds
        ?.map((tag) => project?.features?.find(({ id }) => id === tag.tagId))
        .filter((feature) => feature && !isEmpty(feature?.name)),
    [infoBoxId, zone?.orderedChildTagIds, project?.features]
  );

  const close = useCallback(() => {
    toggleInfoBox(null);
  }, [toggleInfoBox]);

  return (
    <Transition
      show={!!infoBoxId}
      enter="transition-opacity duration-150"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-50"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Card
        header={zone && zone.name}
        subheader={zone && zone.description}
        headerActions={
          <Cancel
            color="orange-light"
            styleProps="hover:cursor-pointer"
            size="20"
            onClick={close}
          />
        }
        classAppend="absolute top-3 left-3 right-3 bottom-3 md:w-1/2 lg:w-1/3 md:right-[null] lg:right-[null] overflow-y-scroll"
      >
        {infoBoxId && (
          <>
            <div className="font-bold text-lg text-brown-dark flex items-center mb-3">
              <Feature size={20} color="brown-dark" styleProps="mr-3" />
              Features
            </div>
            {features.length > 0 ? (
              features.map((feature) => (
                <Card key={feature.id} header={feature.name} classAppend="mb-3">
                  {!isEmpty(feature.description) ? (
                    feature.description
                  ) : (
                    <div className="italic text-brown-light">
                      No description
                    </div>
                  )}
                </Card>
              ))
            ) : (
              <div className="italic text-brown-light">No features added</div>
            )}
          </>
        )}
      </Card>
    </Transition>
  );
};

export { ZoneInfoBox };
