import React from 'react';
import { MapProvider } from '@thrivelot/hooks';
import { PageContainer } from '@thrivelot/stories';
import { HelpPrompt } from '@thrivelot/components';
import { Map } from './Map';
import { ZoneInfoBox } from './ZoneInfoBox';

const PageMap = () => (
  <PageContainer
    icon="Map"
    header="Map"
    documentTitle={'Map'}
    help={
      <HelpPrompt header="About the Project Map">
        This section contains the map of how the project will be laid out.
      </HelpPrompt>
    }
  >
    <MapProvider>
      <div className="h-full relative">
        <Map />
        <ZoneInfoBox />
      </div>
    </MapProvider>
  </PageContainer>
);

export { PageMap };
