import React from "react";
import { getDisplayDataForMilestone } from "@thrivelot/common";
import { Card } from "@thrivelot/stories";
import { CardLoader } from "@thrivelot/stories";
import { useModel } from "@thrivelot/hooks";

const CardProjectStageInfo = ({ projectId }) => {
  const { model: project, loaded } = useModel({ modelName: "Project", id: projectId });

  if (!loaded) return <CardLoader />;

  const stageData = getDisplayDataForMilestone(project.currentMilestone);

  return (
    <Card classAppend="mb-4">
      <div style={{ marginBottom: "5px" }}>
        <div className="text-lg text-brown-dark font-bold mb-4">
          {stageData.emoji} Your Thrive Lot is in{" "}
          <span className={`text-${stageData.color}`}>{stageData.stageName}</span> stage!
        </div>
      </div>
      <div className="text-brown-dark">{stageData.description}</div>
    </Card>
  );
};

export { CardProjectStageInfo };
