import React from "react";
import { useModel } from "@thrivelot/hooks";
import { Card } from "@thrivelot/stories";
import { LeafLoader } from "@thrivelot/stories";
import { ServiceProvider } from "./ServiceProvider";

const CardProjectTeam = ({ projectId }) => {
  const { model: project, loaded, loading } = useModel({ modelName: "Project", id: projectId });

  if (!loaded || loading) return <LeafLoader />;

  return (
    <Card classAppend="mb-4" header="Project Team">
      <div className="flex flex-col gap-6">
        {project.owners.map((ownerId, index) => (
          <ServiceProvider key={ownerId} ownerId={ownerId} isLast={index === project.owners.length - 1} />
        ))}
      </div>
    </Card>
  );
};

export { CardProjectTeam };
