import React, { useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { GoogleMap } from '@react-google-maps/api';
import { useMap, useModel } from '@thrivelot/hooks';
import { isEqual } from '@thrivelot/utils';
import { LeafLoader } from '@thrivelot/stories';
import '@thrivelot/styles/index.css';
import { MapElementHome } from './MapElementHome';
import { MapElementZone } from './MapElementZone';

const Map = () => {
  const {
    loading,
    loadError,
    map,
    center,
    options,
    zoom,
    onLoad,
    onUnmount,
    onCenterChanged,
    onZoomChanged,
    setValue,
  } = useMap();

  const { projectId } = useParams();
  const { model: project, loaded } = useModel({
    modelName: 'Project',
    id: projectId,
  });

  const centerRef = useRef();
  centerRef.current = center;

  const { location } = useMemo(
    () => project?.details?.address || {},
    [project?.details?.address]
  );
  const zones = useMemo(() => project?.zones || [], [project?.zones]);

  useEffect(() => {
    if (location && !centerRef.current && !isEqual(centerRef.current, location))
      setValue('center', location);
  }, [location, setValue]);

  if (!loaded || loading || loadError) return <LeafLoader />;

  return (
    <GoogleMap
      mapContainerClassName="absolute top-0 right-0 bottom-0 left-0"
      mapContainerStyle={{ position: 'absolute' }}
      options={options}
      zoom={zoom}
      center={center}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onDragEnd={onCenterChanged}
      onZoomChanged={onZoomChanged}
    >
      {map && (
        <>
          {zones.map((zone) => (
            <MapElementZone key={zone.id} zone={zone} />
          ))}
          <MapElementHome location={project.details.address.location} />
        </>
      )}
    </GoogleMap>
  );
};

export { Map };
