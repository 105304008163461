import React from 'react';
import { useParams } from 'react-router-dom';
import { useModel } from '@thrivelot/hooks';
import { PageContainer } from '@thrivelot/stories';
import {
  // CardProjectFeatures,
  // CardProjectPriorities,
  // CardProjectStyles,
  // CardProjectPlantPalette,
  HelpPrompt,
  CardProjectStageInfo,
  CardProjectStatus,
  CardProjectTeam,
  CardProjectCalendar,
} from '@thrivelot/components';

const PageProjectDetails = () => {
  const { projectId } = useParams();
  const { model: project, loaded } = useModel({
    modelName: 'Project',
    id: projectId,
  });

  return (
    <PageContainer
      icon="Book"
      header="Details"
      documentTitle={loaded && project.details.title}
      help={
        <HelpPrompt header="About the Project Details Page">
          This section is the primary dashboard for each project. Customer
          contact information, vision, budget, and priorities can be found here.
          It also has a plant palette, which is a summary of all the plants on
          the property, as well as styles and features as entered by the
          customer when they were onboarded.
        </HelpPrompt>
      }
    >
      <div className="columns-1 lg:columns-2">
        <CardProjectStatus projectId={projectId} />
        <CardProjectStageInfo projectId={projectId} />
        <CardProjectTeam projectId={projectId} />
        <CardProjectCalendar projectId={projectId} />
        {/* Commenting this out because the plant palette needs to be refactored to pull the plants from the Bundles */}
        {/* <CardProjectPlantPalette projectId={projectId} /> */}
      </div>
    </PageContainer>
  );
};

export { PageProjectDetails };
