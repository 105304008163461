const constructProjectDisplayName = (props = { project: null }) => {
  const { project } = props;
  if (!project) return "No customer";
  const {
    details: { title },
  } = project;
  if (title && title.trim() !== "") return title;

  if (!project.customer) return "No customer";
  const { firstname, lastname, email } = project.customer;
  if (firstname) return `${firstname} ${lastname}`;
  return email;
};

export { constructProjectDisplayName };
