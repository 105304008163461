import React, { useMemo } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useMarker, useModel } from '@thrivelot/hooks';
import {
  LayoutSimple,
  LayoutSuspense,
  PageUnchartedLands,
  PageProjectFiles,
  PageProjectNotes,
  PageViewInvoiceProposalPlantPalette,
  LayoutProject,
} from '@thrivelot/components';
import {
  PageMap,
  PageProjectInvoiceProposals,
  PageViewProjectInvoiceProposal,
  PageNoProjectsYet,
  PageSettings,
  PageProjectDetails,
  PageMyProjects,
} from '../pages';
import { AfterLoadedRoutes } from './AfterLoadedRoutes';

const CustomerRoutes = ({ userId }) => {
  const { model: user, loaded } = useModel({
    modelName: 'Customer',
    id: userId,
  });

  console.log('userId', userId);
  console.log('User', user);

  if (!loaded || !user) return <LayoutSuspense />;

  return <AfterLoadedRoutes user={user} />;
};

export { CustomerRoutes };
