const milestoneStageNameMap = {
  OPEN_PROJECT: "Envision",
  SITE_ASSESSMENT: "Assess",
  DETAILED_PLAN: "Design",
  INSTALLATION: "Install",
  STEWARDSHIP: "Steward",
};

const milestoneColorMap = {
  OPEN_PROJECT: "blue-main",
  SITE_ASSESSMENT: "green-main",
  DETAILED_PLAN: "orange-main",
  INSTALLATION: "brown-dark",
  STEWARDSHIP: "purple-main",
};

const milestoneDescMap = {
  OPEN_PROJECT:
    "You’re off to a great start! We’re matching you with your local ecosystems specialist and scheduling your Site Assessment.",
  SITE_ASSESSMENT:
    "Your local ecosystems specialist will record observations & recommendations while performing the site visit. Immediately following, your Project Road-map will be finalized with your Project Success Manager. We’ll also share quotes and estimates for the upcoming stages of the project.",
  DETAILED_PLAN:
    "Your ecosystems designer is developing a design which includes a holistic site plan to scale, plant palette, and more. Once you’ve approved your design, your installer will prepare a Project Estimate.",
  INSTALLATION:
    "Your ecosystems installer is bringing your design to life according to the Design and plan set forth in the previous stage. They’re following the Activity Phases as set forth on your Project Calendar.",
  STEWARDSHIP:
    "This is where the magic happens. Stewarding your ecosystem according to principles of ecological succession, catered to the unique needs of your property and your preferences.",
};

const milestoneEmojiMap = {
  OPEN_PROJECT: "🧑‍🌾",
  SITE_ASSESSMENT: "🚜",
  DETAILED_PLAN: "🌰",
  INSTALLATION: "🌱",
  STEWARDSHIP: "🌾",
};

const getDisplayDataForMilestone = (milestone) => ({
  stageName: milestoneStageNameMap[milestone],
  color: milestoneColorMap[milestone],
  description: milestoneDescMap[milestone],
  emoji: milestoneEmojiMap[milestone],
});

export { getDisplayDataForMilestone };
