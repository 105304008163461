import React, { useMemo } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useMarker, useModel } from '@thrivelot/hooks';
import {
  LayoutSimple,
  LayoutSuspense,
  PageUnchartedLands,
  PageProjectFiles,
  PageProjectNotes,
  PageViewInvoiceProposalPlantPalette,
  LayoutProject,
} from '@thrivelot/components';
import {
  PageMap,
  PageProjectInvoiceProposals,
  PageViewProjectInvoiceProposal,
  PageNoProjectsYet,
  PageSettings,
  PageProjectDetails,
  PageMyProjects,
} from '../pages';

const AfterLoadedRoutes = ({ user }) => {
  const { handleMarker } = useMarker();

  const navConfig = useMemo(() => {
    const result = {
      helpOptions: [
        {
          label: '🐞 Submit Bug or Feature Request',
          onClick: handleMarker,
        },
      ],
      navOptions: [
        {
          label: 'Home',
          to: '/',
          icon: 'Home',
        },
      ],
      projectNavOptions: [
        {
          label: 'Details',
          icon: 'Book',
          to: 'details',
        },
        {
          label: 'Notes',
          icon: 'Sketch',
          to: 'notes',
        },
        {
          label: 'Files',
          icon: 'FilesFolder',
          to: 'files',
        },
        {
          label: 'Map',
          icon: 'Map',
          to: 'map',
        },
        {
          label: 'Orders',
          icon: 'LineItems',
          to: 'orders',
        },
      ],
    };

    return result;
  }, [handleMarker, user?.projects]);

  const defaultPath = useMemo(() => {
    // if ((user?.projects?.items || []).length === 0) return '/no-projects-yet';
    if ((user?.projects?.items || []).length === 1)
      return `/projects/${user.projects?.items?.[0].id}`;
    return '/projects';
  }, [user?.projects?.items]);

  const projects = user.projects.items.filter((project) => !project.deleted);

  console.log('defaultPath', defaultPath);

  console.log(
    'Project Ids',
    projects.map(({ id }) => id)
  );

  return (
    <Routes>
      <Route
        path="/"
        element={<LayoutSimple navConfig={navConfig} ensureTerms={true} />}
      >
        {/* If you're logged in and on / or /auth redirect to home */}
        <Route path="auth/*" element={<Navigate to={defaultPath} />} />
        <Route exact path="" element={<Navigate to={defaultPath} />} />

        {/* <Route path="no-projects-yet" element={<PageNoProjectsYet />} /> */}

        <Route path="projects">
          <Route
            exact
            path=""
            element={
              <PageMyProjects projectIds={projects.map(({ id }) => id)} />
            }
          />

          <Route
            path=":projectId"
            element={<LayoutProject navConfig={navConfig} />}
          >
            <Route exact path="" element={<Navigate to="details" replace />} />
            <Route path="details" element={<PageProjectDetails />} />
            <Route path="map" element={<PageMap />} />
            <Route
              path="files/*"
              element={<PageProjectFiles startingView="grid" />}
            />
            <Route path="notes" element={<PageProjectNotes />} />
            <Route path="orders" element={<PageProjectInvoiceProposals />} />
            <Route
              path="orders/:invoiceProposalId"
              element={<PageViewProjectInvoiceProposal />}
            />
            <Route
              path="orders/:invoiceProposalId/plant-palette"
              element={<PageViewInvoiceProposalPlantPalette />}
            />
          </Route>
        </Route>

        <Route path="settings">
          <Route exact path="" element={<PageSettings />} />
        </Route>

        {/* Handle 404 */}
        <Route path="*" element={<PageUnchartedLands />} />
      </Route>
    </Routes>
  );
};

export { AfterLoadedRoutes };
