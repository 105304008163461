import React from 'react';
import {
  ProgressEnvision,
  ProgressAssess,
  ProgressDesign,
  ProgressInstall,
  ProgressSteward,
  Card,
  CardLoader,
} from '@thrivelot/stories';
import {
  constructAddressString,
  constructProjectDisplayName,
  getDisplayDataForMilestone,
} from '@thrivelot/common';
import { useModel } from '@thrivelot/hooks';

const milestoneIconMap = {
  OPEN_PROJECT: ProgressEnvision,
  SITE_ASSESSMENT: ProgressAssess,
  DETAILED_PLAN: ProgressDesign,
  INSTALLATION: ProgressInstall,
  STEWARDSHIP: ProgressSteward,
};

const CardProjectStatus = ({ projectId }) => {
  const { model: project, loaded } = useModel({
    modelName: 'Project',
    id: projectId,
  });

  if (!loaded) return <CardLoader />;

  const title = constructProjectDisplayName({ project });
  const address = constructAddressString(project.details.address);

  const stageData = getDisplayDataForMilestone(project.currentMilestone);
  const ProgressIcon = milestoneIconMap[project.currentMilestone];

  return (
    <Card classAppend="mb-4">
      <div className="flex items-center h-full">
        <ProgressIcon size="100" />
        <div className="flex-1 ml-5">
          <div>
            <div className="text-brown-dark text-xl font-bold">{title}</div>
            <div className="text-brown-dark">{address}</div>
          </div>
          <div className="mt-3 flex">
            <div className="font-bold text-tan-dark mr-3 text-lg">STAGE:</div>
            <div
              className={`bg-${stageData.color} text-sm font-bold rounded-full py-0.5 px-2 text-white-light flex items-center`}
            >
              {stageData.stageName}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export { CardProjectStatus };
